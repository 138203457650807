define("discourse/plugins/discourse-category-restrictor/discourse/initializers/init-category-restrictor", ["exports", "@ember/object", "@ember/runloop", "jquery", "discourse/lib/plugin-api", "discourse/lib/url", "discourse/models/composer", "discourse-common/utils/decorators", "I18n"], function (_exports, _object, _runloop, _jquery, _pluginApi, _url, _composer, _decorators, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: "category-restrictor",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.4.0", api => {
        api.modifyClass("controller:discovery.list", {
          pluginId: "category-restrictor",
          get createTopicDisabled() {
            if (this._super(...arguments)) {
              return true;
            }
            // if not disabled, check if we should disable because of silencing
            return this.model.category && this.currentUser?.silenced_categories?.includes(this.model.category.id);
          }
        });
      });
    }
  };
});