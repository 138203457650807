define("discourse/plugins/discourse-category-restrictor/discourse/templates/connectors/category-custom-security/category-restrictor-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <RestrictorManagerButton @category={{@outletArgs.category}} @location="category-custom-security"/>
  */
  {
    "id": "xPY6J+3r",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@location\"],[[30,1,[\"category\"]],\"category-custom-security\"]],null]],[\"@outletArgs\"],false,[\"restrictor-manager-button\"]]",
    "moduleName": "discourse/plugins/discourse-category-restrictor/discourse/templates/connectors/category-custom-security/category-restrictor-button.hbs",
    "isStrictMode": false
  });
});