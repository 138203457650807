define("discourse/plugins/discourse-category-restrictor/discourse/templates/connectors/before-create-topic-button/category-restrictor-button-moderator", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <RestrictorManagerButton @category={{@outletArgs.category}} @location="before-create-topic-button"/>
  
  */
  {
    "id": "xjUjJ4sf",
    "block": "[[[8,[39,0],null,[[\"@category\",\"@location\"],[[30,1,[\"category\"]],\"before-create-topic-button\"]],null],[1,\"\\n\"]],[\"@outletArgs\"],false,[\"restrictor-manager-button\"]]",
    "moduleName": "discourse/plugins/discourse-category-restrictor/discourse/templates/connectors/before-create-topic-button/category-restrictor-button-moderator.hbs",
    "isStrictMode": false
  });
});